@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Ubuntu:wght@300;400;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000;
}

h1{
    font-family: 'Ubuntu', sans-serif;
    font-size: 2.8rem  !important;
    color: #444 !important;
    font-weight: 700  !important;
}

h2{
    font-family: 'Ubuntu', sans-serif;
    font-size: 2rem !important;
    color: #304584 !important;
    font-weight: 700;
}

h3{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2rem !important;
    color: #304584 !important;
    font-weight: 700;
}

h3 address{
    font-size: 1rem !important;
    font-weight: 300;
}

section
{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
}
section::before
{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(#000,#304584,#000);
  animation: animate 5s linear infinite;
}
@keyframes animate
{
  0%
  {
    transform: translateY(-100%);
  }
  100%
  {
    transform: translateY(100%);
  }
}
section span
{
  position: relative;
  display: block;
  width: calc(6.25vw - 2px);
  height: calc(6.25vw - 2px);
  background: #181818;
  z-index: 2;
  transition: 1.5s;
}
/* section span:hover
{
  background: #304584;
  transition: 0s;
} */

.signin
{
  position: absolute;
  background: #ebeaea;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(0,0,0,9);
  width: 960px;
}

.content
{
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

label{
    font-size: 0.8em;
    color: #304584;
}

.signin .content .form .inputBox
{
  position: relative;
  width: 100%;
}

.signin .content .form .inputBox input
{
  position: relative;
  width: 100%;
  background: #c0c0c0;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 4px;
  border: 1px #222 solid;
  color: #222 !important;
  font-weight: 500;
  font-size: 1em;
  margin-top: 6px;
  margin-bottom: 6px;
}

.signin .content .form .inputBox i
{
  position: absolute;
  left: 0;
  padding: 15px 10px;
  font-style: normal;
  color: #222;
  transition: 0.5s;
  pointer-events: none;
}
.signin .content .form .inputBox input:focus ~ i,
.signin .content .form .inputBox input:valid ~ i
{
  transform: translateY(-7.5px);
  font-size: 0.8em;
  color: #222;
}
.signin .content .form .links
{
  position: relative;
  width: 100%;
  justify-content: space-between;
}
.signin .content .form .links a
{
  color: #222;
  text-decoration: none;
}
.signin .content .form .links a:nth-child(2)
{
  color: #304584;
  font-weight: 600;
}
.signin .content .form .inputBox input[type="submit"]
{
  padding: 12px;
  background: #304584;
  color: #c0c0c0 !important;
  font-weight: 600;
  font-size: 1.35em;
  letter-spacing: 0.05em;
  cursor: pointer;
  transition: 1s;
}

.signin .content .form .inputBox input[type="submit"]:hover
{
  background: #0e3bc0;
  color: #ebeaea !important;
}

footer{
    background-color: #ebeaea;
    position: relative;
    z-index: 1000;
}

input[type="submit"]:active
{
  opacity: 0.6;
}
@media (max-width: 900px)
{
  section span
  {
    width: calc(10vw - 2px);
    height: calc(10vw - 2px);
  }
}
@media (max-width: 600px)
{
  section span
  {
    width: calc(20vw - 2px);
    height: calc(20vw - 2px);
  }
}
